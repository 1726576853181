

























































import {Vue, Component} from "vue-property-decorator";
import Echarts from 'vue-echarts';
import {Home} from "@/request/Home";
import {Order} from "@/request/after-sale/Order";
import orderStore from '@/views/after-sale/OrderStore';

@Component({
    name: 'home'
})
export default class home extends Vue {
    autoPlay: boolean = false;
    options: any = {};
    statData: any = {};
    UserSeven: Array<any> = [];
    msgList: Array<any> = [];
    messageInterval: any;
    autoPlayInterval: any;
    intervalTimeout: number = 10000;
    created() {
        this.flush();
    }
    beforeDestroy() {
        clearInterval(this.messageInterval);
        clearInterval(this.autoPlayInterval);
    }
    flush() {
        let self: any = this;
        Promise.all([
            Home.getStatDataBase(),
            Home.getUserSeven(),
            Home.getPlatformMessage()
        ]).then((res: Array<any>) => {
            this.statData = res[0].data;
            this.UserSeven = res[1].data;
            this.msgList = res[2].data;

            let dates = this.UserSeven.map((j: any) => j.dateStr);
            let activeNums = this.UserSeven.map((j: any) => j.activeNum);
            let newNums = this.UserSeven.map((j: any) => j.newNum);
            let transNums = this.UserSeven.map((j: any) => j.transNum);
            this.initOptions(dates, activeNums, newNums, transNums);

            clearInterval(this.autoPlayInterval);
            this.autoPlayInterval = setInterval(() => Order.notNoticeNum().then((body: any) => {
                if (body.code === 0 && body.data > 0) {
                    orderStore.commit('hasNewOrder', true);
                    if (this.autoPlay) {
                        self.$refs['myaudio'].play();
                        this.$Notice.success({
                            title: '订单通知',
                            duration: 5,
                            desc: `你有${body.data}条新订单`,
                            onClose() {

                            }
                        });
                    }
                }
            }).catch((e: any) => clearInterval(self.autoPlayInterval)), self.intervalTimeout);

            clearInterval(this.messageInterval);
            this.messageInterval = setInterval(() => Home.getPlatformMessage().then((body: any) => this.msgList = body.data).catch((e: any) => clearInterval(this.messageInterval)), self.intervalTimeout);
        })
    }
    openSound() {
        let self: any = this;
        this.autoPlay = !this.autoPlay;
        let audio: any = document.getElementById('myaudio');
    }
    initOptions(dates: any, activeNums: any, newNums: any, transNums: any) {
        this.options = {
            title: {
                left: '1%',
                text: '近7日用户数据'
            },
            grid: {
                left: '3%',
                right: '4%',
                containLabel: true,
                height: '350px'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                right: '4%'
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: dates
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: "活跃人数",
                    type: "line",
                    smooth: true,
                    symbolSize: 8,
                    symbol: 'none',
                    areaStyle: {
                        normal: {
                            color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#61a6c6'
                            }, {
                                offset: 1,
                                color: '#ffe'
                            }])
                        }
                    },
                    data: activeNums,
                    color: '#3ecaff'
                },
                {
                    name: "新增人数",
                    type: "line",
                    smooth: true,
                    symbolSize: 8,
                    symbol: 'none',
                    areaStyle: {
                        normal: {
                            color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#52c63a'
                            }, {
                                offset: 1,
                                color: '#ffe'
                            }])
                        }
                    },
                    data: newNums,
                    color: '#5aff05'
                },
                {
                    name: "交易人数",
                    type: "line",
                    smooth: true,
                    symbolSize: 8,
                    symbol: 'none',
                    areaStyle: {
                        normal: {
                            color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#c6ae36'
                            }, {
                                offset: 1,
                                color: '#ffe'
                            }])
                        }
                    },
                    data: transNums,
                    color: '#ffca10'
                }
            ],
            animationDuration: 2000
        }
    }
}

