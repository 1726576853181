import {BaseRequest} from "@/request/BaseRequest";

class Home extends BaseRequest {

    /**
     * 平台公告
     */
    getPlatformMessage(): any {
        return this.get('/homeData/platformMessage/List');
    }

    /**
     * 商户数据概览
     */
    getStatDataBase(): any {
        return this.get('/homeData/statDataBase/get');
    }

    /**
     * 7日用户
     */
    getUserSeven(): any {
        return this.get('/homeData/userSeven/info');
    }

}

const c = new Home();
export {c as Home};